import { FC } from 'react';

import { Button, Col, Row, Space } from 'antd';

import { HistoryOutlined, SaveOutlined } from '@ant-design/icons';

import { RULES_OPTIONS_TYPE_EDIT, RULES_OPTIONS_TYPE_VIEW } from '../../../constants/Rules';
import { useEscape } from '../../../hooks';
import { OptionsType, RuleModel } from '../../../models/RulesModel';
import closeWithoutSaveConfirmModal from '../../common/closeWithoutSaveConfirmModal';

interface RulesHeaderProps {
    selectedRule: RuleModel;
    onOpenHistory: () => void;
    toggleEditMode: () => void;
    optionType: OptionsType;
    isEdited: boolean;
    onCancel: () => void;
}

const Header: FC<RulesHeaderProps> = ({
    selectedRule,
    onOpenHistory,
    toggleEditMode,
    optionType,
    isEdited,
    onCancel,
}) => {
    const onClose = () => {
        toggleEditMode();
        onCancel();
    };
    const showCloseEditModeModal = () => {
        closeWithoutSaveConfirmModal(onClose);
    };

    useEscape(() => (isEdited ? showCloseEditModeModal() : toggleEditMode()));

    return (
        <Row align="middle">
            <Col span={12}>
                <div className="rules-block__title">{selectedRule.shortName}</div>
            </Col>
            <Col span={12}>
                <Row justify="end">
                    <Space>
                        {optionType === RULES_OPTIONS_TYPE_VIEW && (
                            <>
                                <Button
                                    type="default"
                                    onClick={onOpenHistory}
                                    disabled={!selectedRule.id}
                                    icon={<HistoryOutlined />}>
                                    Історія
                                </Button>
                                <Button type="primary" onClick={toggleEditMode} disabled={!selectedRule.id}>
                                    Редагувати
                                </Button>
                            </>
                        )}
                        {optionType === RULES_OPTIONS_TYPE_EDIT && (
                            <>
                                <Button type="default" onClick={isEdited ? showCloseEditModeModal : toggleEditMode}>
                                    Вийти з режиму редагування
                                </Button>
                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={!isEdited}>
                                    Зберегти зміни
                                </Button>
                            </>
                        )}
                    </Space>
                </Row>
            </Col>
        </Row>
    );
};

export default Header;
