import { FC, useCallback, useMemo } from 'react';
import { Spin, Table, TableProps } from 'antd';
import { RULE_TRANSACTION_SIZE } from '../../../../constants/Alert';
import { RULES_TYPE_CRITERION, RULES_TYPE_INDICATOR } from '../../../../constants/Rules';
import getTableSizeFromStore from '../../../../helpers/getTableSizeFromStore';
import { ExtendedAlert, Rule } from '../../../../models/Alert';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';
import { customerParamsProcessing } from '../../helpers/customerParamsProcessing';
import {
    getTransactionColumns,
    getAccountsColumns,
    nestedRiskColumns,
    riskColumns,
    indicatorsColumns,
    getRelationsColumns,
} from '../../helpers/getColumns';

interface RisksInfoProps {
    model?: ExtendedAlert;
}

const RisksTab: FC<RisksInfoProps> = ({ model }) => {
    const {
        data: dict,
        isLoading: isLoadingDict,
        isFetching: isFetchingDict,
    } = useGetSystemDictionaryQuery('dict_rule_options_descriptions');

    const rules = model?.rules?.filter((item) => item.rule?.type?.code === RULES_TYPE_CRITERION && item.isAuto === 1);

    const indicators = model?.rules?.filter(
        (item) => item.rule?.type?.code === RULES_TYPE_INDICATOR && item.isAuto === 1,
    );

    const expandedRowRender = useCallback(
        (record: Rule) => {
            const size = getTableSizeFromStore(RULE_TRANSACTION_SIZE);
            const ruleTransactionsColumns = getTransactionColumns(size);
            const ruleAccountsColumns = getAccountsColumns(size);
            const ruleRelationsColumns = getRelationsColumns(size, record?.relationsParams?.[0]);
            const { simpleCustomerParamsData, arrayCustomerParamsData, arrayCustomerParamsColumns } =
                customerParamsProcessing(record.customerParams, dict);

            return (
                <div>
                    {!!simpleCustomerParamsData.length && (
                        <Table
                            rowKey="id"
                            className="nestedTable"
                            columns={nestedRiskColumns}
                            dataSource={simpleCustomerParamsData}
                            pagination={false}
                            size="small"
                        />
                    )}

                    {!!arrayCustomerParamsData.length && (
                        <Table
                            rowKey="id"
                            className="nestedTable"
                            columns={arrayCustomerParamsColumns}
                            dataSource={arrayCustomerParamsData}
                            pagination={false}
                            size="small"
                        />
                    )}

                    {!!record.transactionsParams?.length && (
                        <div className="rule-scrolable-table">
                            <Table
                                rowKey="id"
                                className="nestedTable"
                                columns={ruleTransactionsColumns}
                                dataSource={record.transactionsParams}
                                pagination={false}
                                size="small"
                            />
                        </div>
                    )}
                    {!!record.accountsParams?.length && (
                        <div className="rule-scrolable-table">
                            <Table
                                rowKey="id"
                                className="nestedTable"
                                columns={ruleAccountsColumns}
                                dataSource={record.accountsParams}
                                pagination={false}
                                size="small"
                            />
                        </div>
                    )}

                    {!!record.relationsParams?.length && (
                        <div className="rule-scrolable-table">
                            <Table
                                rowKey="id"
                                className="nestedTable"
                                columns={ruleRelationsColumns}
                                dataSource={record.relationsParams}
                                pagination={false}
                                size="small"
                            />
                        </div>
                    )}
                </div>
            );
        },
        [dict],
    );

    const expandable: TableProps<Rule>['expandable'] = useMemo(
        () => ({
            expandedRowRender,
            expandedRowClassName: () => 'rowWrapper',
            rowExpandable: (record: Rule) =>
                !!(
                    (record.customerParams && Object.keys(record.customerParams).length) ||
                    record.transactionsParams?.length ||
                    record.accountsParams?.length ||
                    record.relationsParams?.length
                ),
        }),
        [expandedRowRender],
    );

    return (
        <Spin spinning={isLoadingDict || isFetchingDict}>
            {model?.rules && (
                <Table<Rule>
                    className="marginBottom2"
                    rowKey="id"
                    columns={riskColumns}
                    expandable={expandable}
                    dataSource={rules}
                    pagination={false}
                    size="small"
                    bordered
                />
            )}
            {model?.rules && (
                <Table<Rule>
                    rowKey="id"
                    columns={indicatorsColumns}
                    expandable={expandable}
                    dataSource={indicators}
                    pagination={false}
                    size="small"
                    bordered
                />
            )}
        </Spin>
    );
};

export default RisksTab;
