import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { Filter } from 'odata-query';

interface GetFilters {
    filter: Record<string, FilterValue | null>;
    stringContainsColumns?: string[];
    stringExistColumns?: string[];
    dateColumns?: string[];
    selectColumns?: string[];
}

export const getFilters = ({
    filter,
    stringContainsColumns,
    stringExistColumns,
    dateColumns,
    selectColumns,
}: GetFilters): Filter => {
    let stringFilter = {};
    let dateFilter = {};
    let selectFilter = {};
    for (const key in filter) {
        if (filter[key] !== null) {
            if (dateColumns && dateColumns.includes(key)) {
                dateFilter = {
                    [key]: {
                        ge: {
                            type: 'raw',
                            value: dayjs(filter[key]?.[0] as string)
                                .startOf('day')
                                .toISOString(),
                        },
                        le: {
                            type: 'raw',
                            value: dayjs(filter[key]?.[0] as string)
                                .endOf('day')
                                .toISOString(),
                        },
                    },
                };
                break;
            }

            if (selectColumns && selectColumns.includes(key)) {
                selectFilter = {
                    or: filter.LOG_LEVEL?.map((item) => ({ LOG_LEVEL: { eq: item } })),
                };
                break;
            }

            if (stringContainsColumns && stringContainsColumns.includes(key)) {
                stringFilter = {
                    [key]: { contains: filter[key]?.[0] },
                };
                break;
            }

            if (stringExistColumns && stringExistColumns.includes(key)) {
                stringFilter = {
                    [key]: { eq: filter[key]?.[0] },
                };
                break;
            }
        }
    }
    return [selectFilter, stringFilter, dateFilter];
};
