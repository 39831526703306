import buildQuery from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { GetRequest, GetResponse } from './models/baseType';
import { IntgrStat } from '../../models/IntgrStat';
import { SystemLog } from '../../models/SystemLog';
import { DataProcessingStat } from '../../models/dataProcessingStat';

const apiTag = 'client';

export const systemApi = createApi({
    reducerPath: apiTag + 'Api',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getSystemLog: builder.query<GetResponse<SystemLog>, GetRequest<SystemLog>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag}/system-log${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getIntgrStats: builder.query<GetResponse<IntgrStat>, GetRequest<IntgrStat>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag}/intgr-stats${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getDataProcessingStat: builder.query<DataProcessingStat[], string>({
            query(date) {
                return {
                    url: `${apiTag}/data-processing-stat/${date}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const { useGetSystemLogQuery, useGetIntgrStatsQuery, useGetDataProcessingStatQuery } = systemApi;
