import { HISTORY_STATUS } from "./enums";

export const statusCatalog: { [key: string]: string } = {
    update: 'Змінено',
    create: 'Додано',
    remove: 'Видалено',
};

export const calendarHistoryStatusDesc: { [key: string]: string } = {
    [HISTORY_STATUS.update]: 'Перенесено',
    [HISTORY_STATUS.create]: 'Створення',
    [HISTORY_STATUS.remove]: 'Видалення',
};

export const alertFields: { [key: string]: string } = {
    urgency: 'Терміновість опрацювання',
    status: 'Статус',
    analyst: 'Аналітик',
    conclusion: 'Пропозиції',
    conclusionDate: 'Дата погодження висновку',
    deadlineProvideDocuments: 'Термін надання документів',
    controller: 'Контролер',
    executionDate: 'Планова дата виконання',
    socialStatus: 'Соціальний статус',
    typeOfEmployment: 'Статус зайнятості особи',
    work: 'Місце роботи',
    position: 'Посада',
    listOfRequireDocuments:
        'Перелік документів, які потрібно отримати від клієнта з метою спростування/підтвердження підозр та/або з метою вжиття інших ПЗНП, за результатами яких буде прийнято рішення про ризики подальшого обслуговування клієнта в Банку',
    sendingRequestToCustomerDate: 'Дата направлення запиту клієнту',
    numberOfIncomingLetter: 'Номер вхідного листа',
    providingDocumentsDate: 'Дата отримання Банком документів від клієнта',
    commentsAboutProvidingDocuments: 'Коментарі щодо наданих на запит документів та їх аналізу',
    otherMeasuresOfPZNP: 'Інші заходи ПЗНП',
    negativeInformation:
        'Наявність негативної інформації у відкритих джерелах та/або отриманої від підрозділу безпеки Банку',
    conclusionAboutSuspicions: 'Висновок щодо наявності/відсутності підозр щодо клієнта та його операцій',
    other: 'Інше',
    bankImplementationOfOtherNPC:
        'Інформація щодо вжиття банком інших заходів НПК (зокрема актуалізації даних про клієнта, ПЗНП, результатів моніторингу фінансових операцій)',
    measuresToMinimazeRisks: 'Інші заходи з метою мінімізації ризиків',
    additionalComments: 'Додаткові коментарі',
    controllerComments: 'Коментарі/зауваження контролера',
    kvedQuantity: 'Загальна кількість зареєстрованих КВЕД',
    licensePermitsInfo: 'Інформація щодо наявності ліцензій/дозволів',
    firstCurrentAccountDate: 'Дата відкриття першого поточного рахунку',
    rules: 'Доцільність встановлення критерію',
    isRequestReceived: 'Чи отримав клієнт запит Банку?',
    requestReceivedDate: 'Дата отримання клієнтом запиту Банку',
    isDocumentsProvided: 'Чи надав клієнт на запит Банку документи?',
    commentsAboutRequest: 'Додаткова інформація щодо запиту документів (за наявності)',
    anotherCounterpartiesInfo: 'Коментарі щодо контрагентів (за наявності)',
    minRisksMeasures: 'Заходи, що були вжиті стосовно клієнта з метою мінімізації ризиків ВК/ФТ',
    anotherContactInfo: 'Інша контактна інформація (за наявності)',
    rulesAdditional: 'Вважаю за доцільне додатково встановити наступні критеріїв ризику',
};

export const rulesFields: { [key: string]: string } = {
    description: 'Назва',
    shortName: 'Коротка назва',
    expireInterval: 'Запобігання повторів (дублів)',
    interval: 'Періодичність виявлення',
    isActive: 'Прапор активності',
    riskLevel: 'Рівень ризику',
    score: 'Бали',
    type: 'type',
    urgencyType: 'Терміновість алерта',
    scoreStateEnterprise: 'Бали ДП',
};

export const statusFilter = [
    {
        text: statusCatalog.update,
        value: 'update',
    },
    {
        text: statusCatalog.create,
        value: 'create',
    },
    {
        text: statusCatalog.remove,
        value: 'remove',
    },
];

export const defaultHistoryQuery = {
    orderBy: 'createdDate desc',
    count: true,
};
