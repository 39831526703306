import { RefObject } from 'react';
import { InputRef } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import FilterDropDown from '../components/common/FilterDropDown';
import { DATE_FORMAT_FOR_QUERY } from '../constants/Alert';
import { Account } from '../models/Account';
import { AlienAccount, BaseAlert, Relation } from '../models/Alert';
import { AlertHistory } from '../models/AlertHistory';
import { filterTypes } from '../models/Catalog';
import { DictionaryHistory, RecordModel } from '../models/DictionaryModel';
import { RuleHistory } from '../models/RulesModel';
import { Schedule } from '../models/Schedule';
import { Session, SessionHistory } from '../models/Session';
import { SystemLog } from '../models/SystemLog';
import { EmployeeModel, UserHistoryModel } from '../models/UserModel';

export function access<T, K extends keyof T>(object: T, properties: K | K[]): T[K] | null {
    if (!Array.isArray(properties)) return object[properties] ?? null;

    let current: any = object;
    for (let property of properties) {
        current = current?.[property];
    }
    return current ?? null;
}

export function getColumnSearchProps<
    T extends
        | BaseAlert
        | AlertHistory
        | UserHistoryModel
        | RuleHistory
        | Account
        | Relation
        | AlienAccount
        | SessionHistory
        | Session
        | DictionaryHistory
        | RecordModel
        | SystemLog
        | Schedule,
>(container: RefObject<InputRef>, type: filterTypes, dataIndex?: keyof T | (keyof T)[]): ColumnType<T> {
    return {
        filterDropdown: (filterProps) => <FilterDropDown container={container} type={type} {...filterProps} />,
        filterIcon: () => <SearchOutlined />,
        ...(dataIndex && {
            onFilter: (value, record) => {
                if (type === 'date') {
                    return dayjs(access(record, dataIndex) as string)
                        .format(DATE_FORMAT_FOR_QUERY)
                        .includes((value as string).toLowerCase());
                }

                if (type === 'fio') {
                    const employee = access(record, dataIndex) as EmployeeModel;
                    return (employee.firstName + employee.lastName)
                        ?.toLowerCase()
                        .includes((value as string).toLowerCase());
                }

                return (access(record, dataIndex) as string)
                    ?.toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase());
            },
        }),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => (type === 'date' ? container?.current?.focus() : container?.current?.select()), 100);
            }
        },
    };
}
