import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';
import HistoryRow from '../../../components/common/HistoryRow';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { statusCatalog, defaultHistoryQuery, statusFilter, alertFields } from '../../../constants/History';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import { getHistoryRow } from '../../../helpers/getHistoryValue';
import getTopSkip from '../../../helpers/getTopSkip';
import { AlertHistory } from '../../../models/AlertHistory';
import { useGetAlertHistoryQuery } from '../../../redux/api/alertsApi';

interface HistoryProps {
    id: number;
}

const History: FC<HistoryProps> = ({ id }) => {
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryId: id };

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });
    const [filter, setFilter] = useState<QueryOptions<AlertHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<AlertHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [pagination, filter],
    );

    const { data: alertHistory, isLoading, isFetching } = useGetAlertHistoryQuery({ id: id, queryData: queryData });

    const count = alertHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<AlertHistory>['onChange'] = (newPagination, filters) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const column: ColumnsType<AlertHistory> = [
        {
            title: 'Тип',
            dataIndex: 'action',
            key: 'action',
            render: (value) => statusCatalog[value],
            filters: statusFilter,
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Дата',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<AlertHistory>(dateInputRef, 'date'),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Час',
            dataIndex: 'createdDate',
            key: 'time',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Автор',
            dataIndex: 'userId',
            key: 'user',
            render: (value) => `${value.lastName} ${value.firstName}`,
            ...getColumnSearchProps<AlertHistory>(authorInputRef, 'fio'),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Параметр',
            dataIndex: 'prevValue',
            key: 'param',
            render: (value, record) => {
                let keys = Object.keys(value);
                if (!keys.length) {
                    keys = Object.keys(record.newValue);
                }
                return keys.map((key, index) => <HistoryRow value={alertFields[key] || key} key={index} />);
            },
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "До"',
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value) => getHistoryRow(value),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "Після"',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value) => getHistoryRow(value),
            width: 150,
            ellipsis: true,
        },
    ];

    return (
        <Table<AlertHistory>
            size="small"
            scroll={{ x: 600, y: 600 }}
            rowKey="id"
            bordered
            loading={isLoading || isFetching}
            columns={column}
            dataSource={alertHistory ? alertHistory.items : []}
            pagination={pagination}
            onChange={handleTableChange}
        />
    );
};

export default History;
