import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { getResizableColumn } from './getResizableColumn';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import {
    RULE_TRANSACTION_SIZE,
    DATE_FORMAT,
    TIME_FORMAT,
    RULE_ACCOUNT_SIZE,
    RULE_RELATION_SIZE,
} from '../../../constants';
import { formatNumberToFinance, convertToCurrency } from '../../../helpers/convertCurrency';
import { TableSettings } from '../../../helpers/getTableSizeFromStore';
import { Accounts, RelationParams, Transaction } from '../../../models/Alert';

export const riskColumns = [
    { key: 'code', title: 'Код критерію ризику', dataIndex: ['rule', 'code'], width: 220 },
    { key: 'name', title: 'Найменування критерію ризику', dataIndex: ['rule', 'description'] },
    { key: 'score', title: 'Бали', dataIndex: 'ruleScore' },
];

export const indicatorsColumns = [
    { key: 'code', title: 'Код індикатору підозрілості', dataIndex: ['rule', 'code'], width: 220 },
    { key: 'name', title: 'Назва індикатору підозрілості', dataIndex: ['rule', 'description'] },
];

export const nestedRiskColumns = [
    { key: 'param', title: 'Параметр', dataIndex: 'name' },
    { key: 'value', title: 'Значення', dataIndex: 'value' },
];

export const getTransactionColumns = (size?: TableSettings): ColumnsType<Transaction> => {
    return [
        {
            key: 'document_id',
            title: (
                <ResizableColumn colIndex={0} storageKey={RULE_TRANSACTION_SIZE}>
                    Номер документа
                </ResizableColumn>
            ),
            dataIndex: 'document_id',
            ellipsis: true,
            width: size?.[0] || 106,
        },
        {
            key: 'document_date',
            title: (
                <ResizableColumn colIndex={1} storageKey={RULE_TRANSACTION_SIZE}>
                    Дата документу
                </ResizableColumn>
            ),
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'document_date',
            ellipsis: true,
            width: size?.[1] || 80,
        },
        {
            key: 'amount',
            title: (
                <ResizableColumn colIndex={2} storageKey={RULE_TRANSACTION_SIZE}>
                    Сума операції
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(value),
            dataIndex: 'amount',
            ellipsis: true,
            width: size?.[2] || 106,
        },
        {
            key: 'currency_code',
            title: (
                <ResizableColumn colIndex={3} storageKey={RULE_TRANSACTION_SIZE}>
                    Валюта операції
                </ResizableColumn>
            ),
            dataIndex: 'currency_code',
            ellipsis: true,
            width: size?.[3] || 40,
        },
        {
            key: 'amount_equivalent',
            title: (
                <ResizableColumn colIndex={4} storageKey={RULE_TRANSACTION_SIZE}>
                    Еквівалент в грн
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(value),
            dataIndex: 'amount_equivalent',
            ellipsis: true,
            width: size?.[4] || 106,
        },
        {
            key: 'sender_iban',
            title: (
                <ResizableColumn colIndex={5} storageKey={RULE_TRANSACTION_SIZE}>
                    Номер рахунку платника
                </ResizableColumn>
            ),
            dataIndex: 'sender_iban',
            ellipsis: true,
            width: size?.[5] || 140,
        },
        {
            key: 'sender_bank_code',
            title: (
                <ResizableColumn colIndex={6} storageKey={RULE_TRANSACTION_SIZE}>
                    Код Банку платника
                </ResizableColumn>
            ),
            dataIndex: 'sender_bank_code',
            ellipsis: true,
            width: size?.[6] || 56,
        },
        {
            key: 'sender_bank_name',
            title: (
                <ResizableColumn colIndex={7} storageKey={RULE_TRANSACTION_SIZE}>
                    Банк платника
                </ResizableColumn>
            ),
            dataIndex: 'sender_bank_name',
            ellipsis: true,
            width: size?.[7] || 106,
        },
        {
            key: 'sender_name',
            title: (
                <ResizableColumn colIndex={8} storageKey={RULE_TRANSACTION_SIZE}>
                    Найменування платника
                </ResizableColumn>
            ),
            dataIndex: 'sender_name',
            ellipsis: true,
            width: size?.[8] || 106,
        },
        {
            key: 'sender_tax_code',
            title: (
                <ResizableColumn colIndex={9} storageKey={RULE_TRANSACTION_SIZE}>
                    Ідент.номер / Код платника
                </ResizableColumn>
            ),
            dataIndex: 'sender_tax_code',
            ellipsis: true,
            width: size?.[9] || 80,
        },
        {
            key: 'recipient_iban',
            title: (
                <ResizableColumn colIndex={10} storageKey={RULE_TRANSACTION_SIZE}>
                    Номер рахунку отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipient_iban',
            ellipsis: true,
            width: size?.[10] || 146,
        },
        {
            key: 'recipient_bank_code',
            title: (
                <ResizableColumn colIndex={11} storageKey={RULE_TRANSACTION_SIZE}>
                    Код Банку отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipient_bank_code',
            ellipsis: true,
            width: size?.[11] || 60,
        },
        {
            key: 'recipient_bank_name',
            title: (
                <ResizableColumn colIndex={12} storageKey={RULE_TRANSACTION_SIZE}>
                    Банк отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipient_bank_name',
            ellipsis: true,
            width: size?.[12] || 106,
        },
        {
            key: 'recipient_name',
            title: (
                <ResizableColumn colIndex={13} storageKey={RULE_TRANSACTION_SIZE}>
                    Найменування отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipient_name',
            ellipsis: true,
            width: size?.[13] || 106,
        },
        {
            key: 'recipient_tax_code',
            title: (
                <ResizableColumn colIndex={14} storageKey={RULE_TRANSACTION_SIZE}>
                    Ідент.номер / Код отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipient_tax_code',
            ellipsis: true,
            width: size?.[14] || 80,
        },
        {
            key: 'purpose',
            title: (
                <ResizableColumn colIndex={15} storageKey={RULE_TRANSACTION_SIZE}>
                    Призначення платежу
                </ResizableColumn>
            ),
            dataIndex: 'purpose',
            ellipsis: true,
            width: size?.[15] || 106,
        },
        {
            key: 'payment_date',
            title: (
                <ResizableColumn colIndex={16} storageKey={RULE_TRANSACTION_SIZE}>
                    Дата проведення операції
                </ResizableColumn>
            ),
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'payment_date',
            ellipsis: true,
            width: size?.[16] || 80,
        },
        {
            key: 'payment_time',
            title: (
                <ResizableColumn colIndex={17} storageKey={RULE_TRANSACTION_SIZE}>
                    Час здійснення операції
                </ResizableColumn>
            ),
            render: (value) => (value ? moment(value).format(TIME_FORMAT) : ''),
            dataIndex: 'payment_time',
            ellipsis: true,
            width: size?.[17] || 46,
        },
    ];
};

export const getAccountsColumns = (size?: TableSettings): ColumnsType<Accounts> => {
    return [
        {
            key: 'branch_name',
            title: (
                <ResizableColumn colIndex={0} storageKey={RULE_ACCOUNT_SIZE}>
                    ТВБВ
                </ResizableColumn>
            ),
            dataIndex: 'branch_name',
            ellipsis: true,
            width: size?.[0] || 106,
        },
        {
            key: 'bank_code',
            title: (
                <ResizableColumn colIndex={1} storageKey={RULE_ACCOUNT_SIZE}>
                    МФО РУ
                </ResizableColumn>
            ),
            dataIndex: 'bank_code',
            ellipsis: true,
            width: size?.[1] || 56,
        },
        {
            key: 'bank_name',
            title: (
                <ResizableColumn colIndex={2} storageKey={RULE_ACCOUNT_SIZE}>
                    Назва РУ
                </ResizableColumn>
            ),
            dataIndex: 'bank_name',
            ellipsis: true,
            width: size?.[2] || 106,
        },
        {
            key: 'account_number',
            title: (
                <ResizableColumn colIndex={3} storageKey={RULE_ACCOUNT_SIZE}>
                    Номер рахунку
                </ResizableColumn>
            ),
            dataIndex: 'account_number',
            ellipsis: true,
            width: size?.[3] || 106,
        },
        {
            key: 'account_type',
            title: (
                <ResizableColumn colIndex={4} storageKey={RULE_ACCOUNT_SIZE}>
                    Баланс рахунку
                </ResizableColumn>
            ),
            dataIndex: 'account_type',
            ellipsis: true,
            width: size?.[4] || 40,
        },
        {
            key: 'currency',
            title: (
                <ResizableColumn colIndex={5} storageKey={RULE_ACCOUNT_SIZE}>
                    Валюта рахунку
                </ResizableColumn>
            ),
            dataIndex: 'currency',
            ellipsis: true,
            width: size?.[5] || 36,
        },
        {
            key: 'customer_id',
            title: (
                <ResizableColumn colIndex={6} storageKey={RULE_ACCOUNT_SIZE}>
                    РНК
                </ResizableColumn>
            ),
            dataIndex: 'customer_id',
            ellipsis: true,
            width: size?.[6] || 106,
        },
        {
            key: 'account_name',
            title: (
                <ResizableColumn colIndex={7} storageKey={RULE_ACCOUNT_SIZE}>
                    Найменування рахунку
                </ResizableColumn>
            ),
            dataIndex: 'account_name',
            ellipsis: true,
            width: size?.[7] || 106,
        },
        {
            key: 'iban',
            title: (
                <ResizableColumn colIndex={8} storageKey={RULE_ACCOUNT_SIZE}>
                    IBAN
                </ResizableColumn>
            ),
            dataIndex: 'iban',
            ellipsis: true,
            width: size?.[8] || 140,
        },
        {
            key: 'open_date',
            title: (
                <ResizableColumn colIndex={9} storageKey={RULE_ACCOUNT_SIZE}>
                    Дата відкриття
                </ResizableColumn>
            ),
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'open_date',
            ellipsis: true,
            width: size?.[9] || 82,
        },
        {
            key: 'account_balance',
            title: (
                <ResizableColumn colIndex={10} storageKey={RULE_ACCOUNT_SIZE}>
                    Вхідний залишок
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'account_balance',
            ellipsis: true,
            width: size?.[10] || 106,
        },
        {
            key: 'debet_turns',
            title: (
                <ResizableColumn colIndex={11} storageKey={RULE_ACCOUNT_SIZE}>
                    Обороти ДТ
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'debet_turns',
            ellipsis: true,
            width: size?.[11] || 106,
        },
        {
            key: 'credit_turns',
            title: (
                <ResizableColumn colIndex={12} storageKey={RULE_ACCOUNT_SIZE}>
                    Обороти КТ
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'credit_turns',
            ellipsis: true,
            width: size?.[12] || 106,
        },
        {
            key: 'residual',
            title: (
                <ResizableColumn colIndex={13} storageKey={RULE_ACCOUNT_SIZE}>
                    Фактичний залишок
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'residual',
            ellipsis: true,
            width: size?.[13] || 106,
        },
        {
            key: 'plan_residual',
            title: (
                <ResizableColumn colIndex={14} storageKey={RULE_ACCOUNT_SIZE}>
                    Плановий залишок
                </ResizableColumn>
            ),
            render: (value) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'plan_residual',
            ellipsis: true,
            width: size?.[14] || 106,
        },
        {
            key: 'close_date',
            title: (
                <ResizableColumn colIndex={15} storageKey={RULE_ACCOUNT_SIZE}>
                    Дата закриття
                </ResizableColumn>
            ),
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'close_date',
            ellipsis: true,
            width: size?.[15] || 82,
        },
        {
            key: 'balance_date',
            title: (
                <ResizableColumn colIndex={16} storageKey={RULE_ACCOUNT_SIZE}>
                    Дата останнього руху
                </ResizableColumn>
            ),
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'balance_date',
            ellipsis: true,
            width: size?.[16] || 82,
        },
    ];
};

export const getRelationsColumns = (size?: TableSettings, rowData?: RelationParams): ColumnsType<RelationParams> => {
    const rowDataKeys = rowData
        ? Object.keys(rowData).map((key) => rowData[key as keyof RelationParams] !== 'empty' && key)
        : [];
    const possibleColumns: ColumnsType<RelationParams> = [
        {
            key: 'reft_number',
            title: 'Номер в обмежувальному списку Банку',
            dataIndex: 'reft_number',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'client_name',
            title: 'Найменування/ПІБ',
            dataIndex: 'client_name',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'client_code',
            title: 'ЄДРПОУ/РНОКПП',
            dataIndex: 'client_code',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'birth_day',
            title: 'Дата реєстрації/Дата народження',
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'birth_day',
            ellipsis: true,
            width: 82,
        },
        {
            key: 'relation_name',
            title: "Тип зв'язку",
            dataIndex: 'relation_name',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'vaga1',
            title: 'Частка володіння',
            dataIndex: 'vaga1',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'vaga2',
            title: 'Тип володіння',
            dataIndex: 'vaga2',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'is_bank_client',
            title: 'Клієнт банку',
            dataIndex: 'is_bank_client',
            render: (value) => (value === 1 ? 'Так' : 'Ні'),
            ellipsis: true,
            width: 106,
        },
        {
            key: 'country',
            title: 'Країна',
            dataIndex: 'country',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'citizenship_name',
            title: 'Громодянство',
            dataIndex: 'citizenship_name',
            ellipsis: true,
            width: 106,
        },
    ];

    const columns: ColumnsType<RelationParams> = [];
    possibleColumns.forEach((column) => rowDataKeys.includes(`${column.key}`) && columns.push(column));

    return getResizableColumn(columns, RULE_RELATION_SIZE, size);
};
