import {
    BugOutlined,
    TeamOutlined,
    ClusterOutlined,
    BookOutlined,
    SettingOutlined,
    WarningOutlined,
    BarChartOutlined,
    ClockCircleOutlined,
    DatabaseOutlined,
    BookFilled,
    TableOutlined,
    ApartmentOutlined,
    ProjectOutlined,
    ScheduleOutlined,
} from '@ant-design/icons';
import { BaseRouteModel } from '../../models/RouterModel';
import Alerts from '../../pages/alerts/Alerts';
import DataProcessingStats from '../../pages/dataProcessingStat/DataProcessingStat';
import Dictionary from '../../pages/dictionary/Dictionary';
import IntgrStats from '../../pages/intgrStats/IntgrStats';
import RiskCriteria from '../../pages/riskCriteria/RiskCriteria';
import Roles from '../../pages/roles/Roles';
import Schedulers from '../../pages/schedulers/Schedulers';
import SessionRegister from '../../pages/sessionRegister/SessionRegister';
import SessionsAdministration from '../../pages/sessionsAdministration/SessionsAdministration';
import SuspicionIndicators from '../../pages/suspicionIndicators/SuspicionIndicators';
import SystemLog from '../../pages/systemLog/SystemLog';
import Users from '../../pages/users/Users';
import Vocabulary from '../../pages/vocabulary/Vocabulary';

export enum RouteCode {
    alerts = 'alerts',
    administration = 'administration',
    roles = 'roles',
    users = 'users',
    login = 'login',
    changePassword = 'change-password',
    dictionary = 'dictionary',
    riskCriteria = 'risk-criteria',
    suspicionIndicators = 'suspicion-indicators',
    sessionsAdministration = 'sessions-administration',
    sessionsRegister = 'sessions-register',
    vocabulary = 'vocabulary',
    systemLog = 'system-log',
    intgrStats = 'intgr-stats',
    dataProcessingStat = 'data-processing-stat',
    schedulers = 'schedulers',
}

export const RouteList: readonly BaseRouteModel[] = [
    {
        path: `/${RouteCode.alerts}`,
        code: RouteCode.alerts,
        component: Alerts,
        icon: BugOutlined,
    },
    {
        code: RouteCode.administration,
        icon: SettingOutlined,
    },
    {
        path: `/${RouteCode.roles}`,
        code: RouteCode.roles,
        component: Roles,
        icon: ClusterOutlined,
    },
    {
        path: `/${RouteCode.users}`,
        code: RouteCode.users,
        component: Users,
        icon: TeamOutlined,
    },
    {
        path: `/${RouteCode.dictionary}`,
        code: RouteCode.dictionary,
        component: Dictionary,
        icon: BookOutlined,
    },
    {
        path: `/${RouteCode.riskCriteria}`,
        code: RouteCode.riskCriteria,
        component: RiskCriteria,
        icon: WarningOutlined,
    },
    {
        path: `/${RouteCode.suspicionIndicators}`,
        code: RouteCode.suspicionIndicators,
        component: SuspicionIndicators,
        icon: BarChartOutlined,
    },
    {
        path: `/${RouteCode.sessionsAdministration}`,
        code: RouteCode.sessionsAdministration,
        component: SessionsAdministration,
        icon: ClockCircleOutlined,
    },
    {
        path: `/${RouteCode.sessionsRegister}`,
        code: RouteCode.sessionsRegister,
        component: SessionRegister,
        icon: DatabaseOutlined,
    },
    {
        path: `/${RouteCode.vocabulary}`,
        code: RouteCode.vocabulary,
        component: Vocabulary,
        icon: BookFilled,
    },
    {
        path: `/${RouteCode.systemLog}`,
        code: RouteCode.systemLog,
        component: SystemLog,
        icon: TableOutlined,
    },
    {
        path: `/${RouteCode.intgrStats}`,
        code: RouteCode.intgrStats,
        component: IntgrStats,
        icon: ApartmentOutlined,
    },
    {
        path: `/${RouteCode.dataProcessingStat}`,
        code: RouteCode.dataProcessingStat,
        component: DataProcessingStats,
        icon: ProjectOutlined,
    },
    {
        path: `/${RouteCode.schedulers}`,
        code: RouteCode.schedulers,
        component: Schedulers,
        icon: ScheduleOutlined,
    },
];
