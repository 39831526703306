export const STATUS_CODE_NEW = 'new';
export const STATUS_CODE_ASSIGNED = 'assigned';
export const STATUS_CODE_PROCESS = 'process';
export const STATUS_CODE_PROCESSED = 'processed';
export const STATUS_CODE_AGREED = 'agreed';
export const STATUS_CODE_AMENDMENT = 'amendment';
export const STATUS_CODE_CLOSED = 'closed';

export const PRIORITY_CODE_IMMEDIATELY = 'immediately';
export const PRIORITY_CODE_PRIORITY = 'priority';
export const PRIORITY_CODE_USUAL = 'usual';
export const PRIORITY_CODE_ARCHIVE = 'archive';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm:ss';
export const DATE_FORMAT_FOR_QUERY = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const TIME_WITH_SECONDS_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT_FOR_ORACLE_QUERY = 'DD-MMM-YYYY hh:mm:ss A';
export const ALLOWED_CODES_FOR_ASSIGN = [
    STATUS_CODE_NEW,
    STATUS_CODE_ASSIGNED,
    STATUS_CODE_PROCESS,
    STATUS_CODE_AMENDMENT,
];
export const ALLOWED_CODES_FOR_AGREED = [STATUS_CODE_PROCESSED];
export const ALLOWED_CODES_FOR_AMENDMENT = [STATUS_CODE_PROCESSED];
export const ALLOWED_CODES_FOR_PRIORITY = [
    STATUS_CODE_NEW,
    STATUS_CODE_ASSIGNED,
    STATUS_CODE_PROCESS,
    STATUS_CODE_AMENDMENT,
    STATUS_CODE_PROCESSED,
];
export const ALLOWED_CODES_FOR_DEADLINE = [
    STATUS_CODE_NEW,
    STATUS_CODE_ASSIGNED,
    STATUS_CODE_PROCESS,
    STATUS_CODE_AMENDMENT,
    STATUS_CODE_PROCESSED,
];
export const ALLOWED_CODES_FOR_ASSIGN_CONTROLLER = [
    STATUS_CODE_NEW,
    STATUS_CODE_ASSIGNED,
    STATUS_CODE_PROCESS,
    STATUS_CODE_AMENDMENT,
    STATUS_CODE_PROCESSED,
];

export const ALLOWED_CODES_FOR_PROCESS = [STATUS_CODE_ASSIGNED];
export const ALLOWED_CODES_FOR_PROCESSED = [STATUS_CODE_PROCESS, STATUS_CODE_AMENDMENT];
export const ALLOWED_CODES_FOR_CLOSED = [STATUS_CODE_AGREED];

export const CUSTOMIZED_STATUS_CODES = [
    STATUS_CODE_PROCESS,
    STATUS_CODE_PROCESSED,
    STATUS_CODE_AMENDMENT,
    STATUS_CODE_AGREED,
    STATUS_CODE_ASSIGNED,
];

export const CUSTOMER_TYPES_INDIVIDUAL = 'Фізична особа';
export const CUSTOMER_TYPES_INDIVIDUAL_ENTREPRENEUR = 'Фізична особа підприємець';
export const CUSTOMER_TYPES_LEGAL = 'Юридична особа';
export const CUSTOMER_TYPES_LEGAL_BANK = 'Юридична особа - банк';

export const LEGAL_TYPES = [CUSTOMER_TYPES_LEGAL, CUSTOMER_TYPES_LEGAL_BANK];
export const INDIVIDUAL_TYPES = [CUSTOMER_TYPES_INDIVIDUAL, CUSTOMER_TYPES_INDIVIDUAL_ENTREPRENEUR];

export const CUSTOMER_TYPES_FILTER = [
    { text: 'ФО', value: CUSTOMER_TYPES_INDIVIDUAL },
    { text: 'ФОП', value: CUSTOMER_TYPES_INDIVIDUAL_ENTREPRENEUR },
    { text: 'ЮО', value: CUSTOMER_TYPES_LEGAL },
    { text: 'ЮОБ', value: CUSTOMER_TYPES_LEGAL_BANK },
];

export const ALERT_PARAMETER = Object.freeze({
    urgency: 'urgency',
    status: 'status',
    id: 'id',
    createdDate: 'createdDate',
    sessionCode: ['session', 'number'],
    sessionStartDate: ['session', 'startDate'],
    executionDate: 'executionDate',
    customerTypeName: ['customer', 'customerTypeName'],
    customerNationalName: ['customer', 'nationalName'],
    customerCode: ['customer', 'customerCode'],
    customerId: ['customer', 'customerId'],
    customerBankCode: ['customer', 'bankCode'],
    nameRa: 'nameRa',
    customerBranchNameDescription: ['customer', 'branchName', 'description'],
    branchNameDescription: ['branchName', 'description'],
    analyst: 'analyst',
    analystId: ['analyst', 'id'],
    deadlineDate: 'deadlineProvideDocuments',
    conclusion: 'conclusion',
    conclusionDescription: ['conclusion', 'description'],
    conclusionDate: 'conclusionDate',
    controller: 'controller',
    controllerId: ['controller', 'id'],
    indicatorCodes: 'indicatorCodes',
    criterionCodes: 'criterionCodes',
    rulesRuleId: ['rule', 'id'],
    rulesRuleCode: ['rule', 'code'],
    rulesRuleCodeCriterion: ['rule', 'code', 'criterion'],
    rulesRuleCodeIndicator: ['rule', 'code', 'indicator'],
    ruleTypeCode: ['rule', 'type', 'code'],
    sendingRequestToCustomerDate: 'sendingRequestToCustomerDate',
    providingDocumentsDate: 'providingDocumentsDate',
    rules: 'rules',
    rulesIsAuto: ['rules', 'isAuto'],
    closeDate: 'closeDate',
    analysisPeriod: 'analysisPeriod',
    analystEmail: ['analyst', 'email'],
    controllerEmail: ['controller', 'email'],
    isRequestReceived: 'isRequestReceived',
    isDocumentsProvided: 'isDocumentsProvided',
    minRisksMeasures: 'minRisksMeasures',
    rulesAdditional: 'rulesAdditional',
    requestReceivedDate: 'requestReceivedDate',
});
export const RULE_TRANSACTION_SIZE = 'ruleTransactionSize';
export const RULE_ACCOUNT_SIZE = 'ruleAccountSize';
export const RULE_RELATION_SIZE = 'ruleRelationSize';
export const SESSION_STORAGE_KEY = 'alertTableSize';
export const OPERATION_PARAMS_TABLE = 'operationParamsSize';
export const ACCOUNTS_INFO_TABLE = 'accountsInfoSize';
export const OTHER_ACCOUNTS_TABLE = 'otherAccountsSize';
export const ACCOUNT_TURNOVER_TABLE = 'accountTurnoverSize';
export const RELATIVE_TABLE = 'relativeSize';
export const CONTRACTORS_TABLE = 'contractorsSize';
export const CLIENT_ALERTS_TABLE = 'clientAlertsSize';
export const OTHER_RNK_TABLE = 'otherRnkSize';
export const SYSTEM_LOG_TABLE = 'systemLogSize';
export const INTGR_STATS_TABLE = 'intgrStatsSize';
export const DATA_PROCESSING_STAT_TABLE = 'dataProcessingStatSize';
export const SCHEDULERS_TABLE = 'schedulersSize';
