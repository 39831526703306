import { ColumnsType } from 'antd/es/table';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATA_PROCESSING_STAT_TABLE } from '../../../constants';
import { DataProcessingStat } from '../../../models/dataProcessingStat';

const defaultColumnsWidth = {
    0: '40%',
    1: '10%',
    2: '10%',
    3: '10%',
    4: '10%',
    5: '10%',
    6: '10%',
} as const;

export const getColumns = (sizes: Record<string, number>): ColumnsType<DataProcessingStat> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Назва топіку
                </ResizableColumn>
            ),
            dataIndex: 'topic',
            key: 'topic',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Оброблено
                </ResizableColumn>
            ),
            dataIndex: 'processedСount',
            key: 'processedСount',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Не оброблено
                </ResizableColumn>
            ),
            dataIndex: 'unprocessedСount',
            key: 'unprocessedСount',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Помилка обробки
                </ResizableColumn>
            ),
            dataIndex: 'errorСount',
            key: 'errorСount',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Пропущено
                </ResizableColumn>
            ),
            dataIndex: 'missedСount',
            key: 'missedСount',
            ellipsis: true,
            width: sizes?.[4] || defaultColumnsWidth[4],
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Інше
                </ResizableColumn>
            ),
            dataIndex: 'otherСount',
            key: 'otherСount',
            ellipsis: true,
            width: sizes?.[5] || defaultColumnsWidth[5],
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={DATA_PROCESSING_STAT_TABLE}>
                    Всього
                </ResizableColumn>
            ),
            dataIndex: 'totalСount',
            key: 'totalСount',
            ellipsis: true,
            width: sizes?.[6] || defaultColumnsWidth[6],
        },
    ];
};
