import { useEffect, useMemo, useRef, useState } from 'react';
import { notification, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { QueryOptions } from 'odata-query';
import {
    dateColumns,
    getColumns,
    selectColumns,
    stringContainsColumns,
    stringExistColumns,
} from './components/getColumns';
import { SYSTEM_LOG_TABLE } from '../../constants';
import { getFilters } from '../../helpers/getFilters';
import getTopSkip from '../../helpers/getTopSkip';
import { SystemLog } from '../../models/SystemLog';
import { useGetSystemLogQuery } from '../../redux/api/systemApi';

const defaultOrder = 'ID desc';

const SystemLogs = () => {
    const inputRef = useRef(null);
    const [filter, setFilter] = useState<Record<string, FilterValue | null>>({});
    const [order, setOrder] = useState<QueryOptions<SystemLog>['orderBy']>(defaultOrder);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(SYSTEM_LOG_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const queryData = useMemo<Partial<QueryOptions<SystemLog>>>(() => {
        const allFilter = getFilters({ filter, stringContainsColumns, stringExistColumns, dateColumns, selectColumns });

        return {
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: allFilter,
            orderBy: order,
        };
    }, [filter, pagination, order]);

    const { data, error, isLoading } = useGetSystemLogQuery(queryData);

    const handleTableChange: TableProps<SystemLog>['onChange'] = (newPagination, filters, sorter: any) => {
        setPagination(newPagination);
        if (sorter.order) setOrder(`${sorter?.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`);
        setFilter(filters);
    };

    const items = data?.items;
    const count = data?.count;

    useEffect(() => setPagination((currentPagination) => ({ ...currentPagination, total: count })), [count]);

    useEffect(() => {
        if (error) notification.error((error as any)?.data?.message);
    }, [error]);

    return (
        <Spin spinning={isLoading}>
            <Table<SystemLog>
                rowKey="ID"
                size="small"
                scroll={{ x: 600 }}
                columns={getColumns(sizes, inputRef)}
                dataSource={items}
                pagination={pagination}
                onChange={handleTableChange}
                bordered
            />
        </Spin>
    );
};

export default SystemLogs;
