import { getUserName } from './getUserName';
import { RoleModel } from '../models/RoleModel';
import { EmployeeModel } from '../models/UserModel';

interface IRole {
    controller_1st: EmployeeModel;
    controller_2nd: EmployeeModel;
    analyst: EmployeeModel;
    admin: EmployeeModel;
}

export const getUniqueUserByRole = (roles: RoleModel[] | undefined, role: keyof IRole | (keyof IRole)[]) => {
    return (
        roles
            ?.filter((item) => {
                if (Array.isArray(role)) {
                    return role.includes(item.name as keyof IRole);
                }
                return item.name === role;
            })[0]
            .users?.map((item) => ({
                text: getUserName(item),
                value: item.id,
            })) || []
    );
};
