import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { DISABLED_WHITESPACE } from '../../../constants/common';

interface IProps {
    name: (string | number)[];
    label: string;
    min?: number;
    max?: number;
}
const ParameterArray = ({ min, max, name, label }: IProps) => (
    <Row gutter={[8, 8]}>
        <Form.List name={name}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key }) => (
                        <Col key={key}>
                            <Form.Item
                                name={`${key}`}
                                rules={[
                                    { required: true },
                                    { whitespace: true, message: DISABLED_WHITESPACE },
                                    {
                                        min,
                                        max,
                                        // eslint-disable-next-line no-template-curly-in-string
                                        message: 'довжина має бути від ${min} до ${max} символів',
                                    },
                                ]}>
                                <MaskedInput
                                    style={{
                                        width: max ? `${max * 9 + 22}px` : undefined,
                                    }}
                                    mask={[{ mask: Number, scale: 0 }]}
                                />
                            </Form.Item>
                        </Col>
                    ))}
                    {fields.length > 1 && (
                        <Col>
                            <Button type="primary" danger onClick={() => remove(fields.length - 1)}>
                                Видалити {label}
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button type="primary" onClick={() => add()}>
                            Додати {label}
                        </Button>
                    </Col>
                </>
            )}
        </Form.List>
    </Row>
);

export default ParameterArray;
