import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Dropdown, Row, Space, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { QueryOptions } from 'odata-query';
import { CaretDownOutlined, PrinterOutlined } from '@ant-design/icons';
import { dateColumns, getColumns, stringContainsColumns, stringExistColumns } from './components/getColumns';
import Modal from '../../components/common/Modal';
import ReportModal from '../../components/modal/ReportModal';
import { INTGR_STATS_TABLE } from '../../constants';
import { ReportTypes } from '../../constants/enums';
import { getSorterQuery } from '../../helpers';
import { getFilters } from '../../helpers/getFilters';
import getTopSkip from '../../helpers/getTopSkip';
import { useModal } from '../../hooks';
import { useHandleError } from '../../hooks/useHandleError';
import { IntgrStat } from '../../models/IntgrStat';
import { useGetIntgrStatsQuery } from '../../redux/api/systemApi';

const defaultOrder = 'id desc';

const IntgrStats = () => {
    const inputRef = useRef(null);
    const [reportModal, openReportModal, closeReportModal] = useModal();
    const [filter, setFilter] = useState<Record<string, FilterValue | null>>({});
    const [order, setOrder] = useState<QueryOptions<IntgrStat>['orderBy']>(defaultOrder);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(INTGR_STATS_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const queryData = useMemo<Partial<QueryOptions<IntgrStat>>>(() => {
        const allFilter = getFilters({ filter, stringContainsColumns, stringExistColumns, dateColumns });

        return {
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: allFilter,
            orderBy: order,
        };
    }, [filter, pagination, order]);

    const { data, error, isLoading } = useGetIntgrStatsQuery(queryData);

    const handleTableChange: TableProps<IntgrStat>['onChange'] = (newPagination, filters, sorter) => {
        setPagination(newPagination);
        setOrder(getSorterQuery(sorter));
        setFilter(filters);
    };

    const items = data?.items;
    const count = data?.count;

    useEffect(() => setPagination((currentPagination) => ({ ...currentPagination, total: count })), [count]);

    useHandleError(error);

    return (
        <Spin spinning={isLoading}>
            <Modal
                onCancel={closeReportModal}
                destroyOnClose
                title={`Друк звіту "Інтеграція з АБС"`}
                open={reportModal}>
                <ReportModal
                    onSuccess={closeReportModal}
                    reportType={ReportTypes.intgrStats}
                    filters={filter}
                    processedFilters={getFilters({ filter, stringContainsColumns, stringExistColumns, dateColumns })}
                />
            </Modal>
            <Row gutter={8} justify="end" className="marginBottom2 alignItemsCenter">
                <Col>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: `Друк звіту "Інтеграція з АБС"`,
                                    onClick: openReportModal,
                                },
                            ],
                        }}>
                        <Button>
                            <Space>
                                <PrinterOutlined />
                                <CaretDownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <Table<IntgrStat>
                rowKey="id"
                size="small"
                scroll={{ x: 600 }}
                columns={getColumns(sizes, inputRef)}
                dataSource={items}
                pagination={pagination}
                onChange={handleTableChange}
                bordered
            />
        </Spin>
    );
};

export default IntgrStats;
