export enum ReportTypes {
    alertConclusion = 'alert-conclusion',
    alertRegister = 'alert-register',
    businessRelationships = 'business-relationships',
    untriggeredRisks = 'untriggered-risks',
    untriggeredIndicators = 'untriggered-indicators',
    transactions = 'transactions',
    accounts = 'accounts',
    accountsTurnover = 'accounts-turnover',
    sessionRegister = 'session-register',
    sessionCalendarHistory = 'session-calendar-history',
    activity = 'activity',
    intgrStats = 'intgr-stats',
    dataProcessingStat = 'data-processing-stat',
}
