import React, { FC, useEffect, useMemo } from 'react';

import { notification, Spin } from 'antd';

import Content from './components/Content';

import { simpleRulesQuery } from '../../constants/Rules';
import { BASE_NOTIFICATION_CONFIG } from '../../constants/common';
import { filterRules } from '../../helpers/filterRules';
import { useGetRulesQuery } from '../../redux/api/rulesApi';

interface RulesProps {
    type: string;
}

const Rules: FC<RulesProps> = ({ type }) => {
    const { data: rules, error, isLoading, isFetching } = useGetRulesQuery(simpleRulesQuery);

    const filtered = useMemo(() => (rules ? filterRules(rules, type) : []), [rules, type]);

    useEffect(() => {
        if (error) {
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: (error as any)?.data?.message,
            });
        }
    }, [error]);

    return <Spin spinning={isLoading || isFetching}>{filtered && <Content collection={filtered} />}</Spin>;
};
export default Rules;
