import { FilterValue } from 'antd/es/table/interface';
import moment from 'moment/moment';
import { DATE_FORMAT_FOR_ORACLE_QUERY } from '../constants/Alert';

export const makeFilterQuery = (data?: Record<string, FilterValue | null>) => {
    if (!data) return [];
    let filtersObject = [];
    let equalFilters = [];
    let containFilters = [];

    if (data.number) {
        containFilters.push({
            number: { contains: data.number[0] },
        });
    }
    if (data.startDate) {
        equalFilters.push({
            startDate: {
                gt: moment(data.startDate[0] as string)
                    .startOf('day')
                    .format(DATE_FORMAT_FOR_ORACLE_QUERY),
                lt: moment(data.startDate[0] as string)
                    .endOf('day')
                    .format(DATE_FORMAT_FOR_ORACLE_QUERY),
            },
        });
    }
    if (data.endDate) {
        equalFilters.push({
            endDate: {
                gt: moment(data.endDate[0] as string)
                    .startOf('day')
                    .format(DATE_FORMAT_FOR_ORACLE_QUERY),
                lt: moment(data.endDate[0] as string)
                    .endOf('day')
                    .format(DATE_FORMAT_FOR_ORACLE_QUERY),
            },
        });
    }
    if (data.criterionPlan) {
        containFilters.push({
            or: data.criterionPlan.map((item) => ({ criterionPlan: { contains: item } })),
        });
    }
    if (data.criterionFact) {
        containFilters.push({
            or: data.criterionFact.map((item) => ({ criterionFact: { contains: item } })),
        });
    }
    if (data.indicatorPlan) {
        containFilters.push({
            or: data.indicatorPlan.map((item) => ({ indicatorPlan: { contains: item } })),
        });
    }
    if (data.indicatorFact) {
        containFilters.push({
            or: data.indicatorFact.map((item) => ({ indicatorFact: { contains: item } })),
        });
    }
    if (data.status) {
        equalFilters.push({
            or: data.status.map((item) => ({ status: { eq: item } })),
        });
    }
    if (data.intervals) {
        equalFilters.push({
            // NOTE: filterIntervals is the join alias only
            or: data.intervals.map((item) => ({ filterIntervals: { intervalCode: { eq: item } } })),
        });
    }
    if (data.createdUser) {
        equalFilters.push({
            or: data.createdUser.map((item) => ({ createdUser: { eq: item || null } })),
        });
    }
    if (data.period) {
        equalFilters.push({
            periodFromDate: {
                ge: moment(data.period[0] as string)
                    .startOf('day')
                    .format(DATE_FORMAT_FOR_ORACLE_QUERY),
            },
            periodToDate: {
                le: moment(data.period[1] as string)
                    .endOf('day')
                    .format(DATE_FORMAT_FOR_ORACLE_QUERY),
            },
        });
    }

    filtersObject = [...containFilters, ...equalFilters];
    return filtersObject;
};
