import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse, Row, Col, Space, Button, Table, Spin, Select, notification } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnType } from 'antd/es/table/interface';
import { TableRowSelection } from 'antd/lib/table/interface';
import { QueryOptions } from 'odata-query';
import EditableCell from './EditableCell';
import { BASE_NOTIFICATION_CONFIG } from '../../../../constants/common';
import { sortDictionaryRecordByCode } from '../../../../helpers/sort';
import { isArray } from '../../../../helpers/valueTypeCheck';
import { RecordModel } from '../../../../models/DictionaryModel';
import { RuleModel, RuleParamModel } from '../../../../models/RulesModel';
import { useGetDictionaryQuery } from '../../../../redux/api/dictionaryApi';
import { useUpdateRuleMutation } from '../../../../redux/api/rulesApi';

interface DataType {
    index: number;
    code: string;
    description: string;
}

const components = {
    body: {
        cell: EditableCell,
    },
};

const dictionaryColumns: ColumnType<DataType>[] = [
    {
        title: '№ з/п',
        dataIndex: 'index',
        sorter: (a: any, b: any) => a.index - b.index,
        width: 80,
    },
    {
        title: 'Код',
        dataIndex: 'code',
        sorter: (a: any, b: any) => (a.code?.localeCompare(b.code, undefined, { numeric: true }) > 0 ? 1 : -1),
        defaultSortOrder: 'ascend',
        width: 80,
        ellipsis: true,
    },
    {
        title: 'Опис',
        dataIndex: 'description',
        sorter: (a: any, b: any) => (a.description < b.description ? -1 : a.description > b.description ? 1 : 0),
    },
];
export interface CollapsedVocabProps {
    model: RuleParamModel;
    ruleId: number;
}
const Dictionary = ({ model, ruleId }: CollapsedVocabProps) => {
    const queryData: Partial<QueryOptions<RecordModel>> | undefined = useMemo(() => {
        if (model.dictionaryName === 'dict_abs_decisions') {
            return {
                select: ['typeCode', 'typeName'],
                filter: {
                    typeCode: {
                        ne: null,
                    },
                },
            };
        }
    }, [model.dictionaryName]);
    const {
        data: records,
        isLoading,
        error,
    } = useGetDictionaryQuery({ dictionaryName: `${model.dictionaryName}`, queryData });
    const [updateRule, { isLoading: isUpdating }] = useUpdateRuleMutation();
    const selectWrapperRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState<Array<string>>(model.value as Array<string>);
    const [newRecords, setNewRecords] = useState<Array<string>>([]);
    const [isChanged, setChanged] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const dictionary: RecordModel[] = useMemo(
        () => (isArray(records) ? (records as unknown as RecordModel[]) : []),
        [records],
    );

    const code = model.dictionaryName === 'dict_abs_decisions' ? 'typeCode' : 'code';
    const description = model.dictionaryName === 'dict_abs_decisions' ? 'typeName' : 'description';

    const sortedWholeDictionary = useMemo(
        () => sortDictionaryRecordByCode(dictionary?.filter((item) => isArray(values) && values.includes(item[code]))),
        [code, dictionary, values],
    );

    const inactiveItemsDictionary = useMemo(
        () => dictionary?.filter((item) => isArray(values) && !values.includes(item[code])),
        [code, dictionary, values],
    );

    const data = useMemo(() => {
        let result: DataType[] = [];
        if (sortedWholeDictionary?.length) {
            result = sortedWholeDictionary.map((item, index) => {
                return {
                    code: item[code],
                    description: item[description],
                    index: index + 1,
                };
            });
        }
        return result;
    }, [code, description, sortedWholeDictionary]);
    const options = useMemo(() => {
        let result: DefaultOptionType[] = [];

        if (inactiveItemsDictionary?.length)
            inactiveItemsDictionary.forEach((record) => {
                if (record[code]) {
                    result.push({
                        label: `${record[code]} - ${record[description]}`,
                        value: record[code],
                    });
                }
            });
        return result;
    }, [code, description, inactiveItemsDictionary]);

    const handleSave = (e: SyntheticEvent) => {
        e.stopPropagation();
        updateRule({
            id: ruleId,
            params: [
                {
                    id: model.id,
                    key: model.key,
                    value: values,
                },
            ],
        } as RuleModel)
            .unwrap()
            .then(() => {
                setChanged(false);
                notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Довідник успішно оновлено!' });
            })
            .catch((e) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: e.data.message }));
    };
    const handleDelete = (e: SyntheticEvent) => {
        e.stopPropagation();
        setValues(values.filter((item) => !selectedRowKeys.includes(item)));
        setSelectedRowKeys([]);
        setChanged(true);
    };
    const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys), []);
    const handleAddRecord = () => {
        setValues((prev) => (prev ? [...prev, ...newRecords] : [...newRecords]));
        setNewRecords([]);
        setChanged(true);
    };

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        if (error) {
            notification.error({ ...BASE_NOTIFICATION_CONFIG, message: (error as any)?.data?.message });
        }
    }, [error]);

    return (
        <Spin spinning={isLoading || isUpdating}>
            <Collapse onChange={(values) => setOpen(!!(values as Array<string>)?.length)}>
                <Collapse.Panel
                    className="rules-collapse__vocab_panel"
                    header={
                        <Row justify="space-between">
                            <Col>
                                <b>{model.label}</b>
                            </Col>
                            <Col>
                                <Space>
                                    <Button
                                        disabled={!open || isLoading || !selectedRowKeys.length || isUpdating}
                                        type="primary"
                                        danger
                                        onClick={handleDelete}>
                                        Видалити
                                    </Button>
                                    <Button
                                        disabled={!open || isLoading || isUpdating || !isChanged}
                                        type="primary"
                                        onClick={handleSave}>
                                        Зберегти зміни
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    }
                    key="1">
                    <div className="dictionary-select" ref={selectWrapperRef}>
                        <Select
                            maxTagTextLength={30}
                            value={newRecords}
                            mode="multiple"
                            placeholder="Додати значення (код)"
                            onChange={(values) => setNewRecords(values)}
                            className="dictionary-select__input"
                            filterOption={(value, option) =>
                                !!`${option?.label}`.toLowerCase().includes(value.toLowerCase())
                            }
                            options={options}
                            getPopupContainer={() => selectWrapperRef.current as HTMLDivElement}
                        />
                        <Button onClick={handleAddRecord}>Додати</Button>
                    </div>
                    <Table<DataType>
                        size="small"
                        components={components}
                        rowClassName={() => 'editable-row'}
                        rowSelection={rowSelection}
                        rowKey="code"
                        bordered={false}
                        dataSource={data}
                        columns={dictionaryColumns}
                    />
                </Collapse.Panel>
            </Collapse>
        </Spin>
    );
};

export default Dictionary;
