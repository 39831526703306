import { RefObject } from 'react';
import { InputRef } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATE_FORMAT_WITH_TIME, SYSTEM_LOG_TABLE } from '../../../constants';
import { logLevelMap } from '../../../constants/enums/LogLevel';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { SystemLog } from '../../../models/SystemLog';

const logLevelFilter = Object.entries(logLevelMap).map(([key, value]) => ({ text: value, value: key }));

const defaultColumnsWidth = {
    0: 46,
    1: 73,
    2: 66,
    3: 236,
    4: 76,
    5: 61,
    6: 236,
    7: 236,
} as const;

export const stringContainsColumns = ['ID'];
export const stringExistColumns = ['NAME', 'MSG', 'USER_ID', 'DATA', 'ERROR_STACK'];
export const dateColumns = ['EVENT_TIME'];
export const selectColumns = ['LOG_LEVEL'];

export const getColumns = (sizes: Record<string, number>, ref: RefObject<InputRef>): ColumnsType<SystemLog> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={SYSTEM_LOG_TABLE}>
                    ID
                </ResizableColumn>
            ),
            dataIndex: 'ID',
            key: 'ID',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={SYSTEM_LOG_TABLE}>
                    NAME
                </ResizableColumn>
            ),
            dataIndex: 'NAME',
            key: 'NAME',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={SYSTEM_LOG_TABLE}>
                    LOG_LEVEL
                </ResizableColumn>
            ),
            dataIndex: 'LOG_LEVEL',
            key: 'LOG_LEVEL',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
            render: (logLevel: number) =>
                logLevelMap?.[logLevel as keyof typeof logLevelMap] || `Unknown (${logLevel})`,
            filters: logLevelFilter,
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={SYSTEM_LOG_TABLE}>
                    MSG
                </ResizableColumn>
            ),
            dataIndex: 'MSG',
            key: 'MSG',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={SYSTEM_LOG_TABLE}>
                    EVENT_TIME
                </ResizableColumn>
            ),
            dataIndex: 'EVENT_TIME',
            key: 'EVENT_TIME',
            ellipsis: true,
            width: sizes?.[4] || defaultColumnsWidth[4],
            render: (value) => (value ? moment(value).format(DATE_FORMAT_WITH_TIME) : ''),
            ...getColumnSearchProps<SystemLog>(ref, 'date'),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={SYSTEM_LOG_TABLE}>
                    USER_ID
                </ResizableColumn>
            ),
            dataIndex: 'USER_ID',
            key: 'USER_ID',
            ellipsis: true,
            width: sizes?.[5] || defaultColumnsWidth[5],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={SYSTEM_LOG_TABLE}>
                    DATA
                </ResizableColumn>
            ),
            dataIndex: 'DATA',
            key: 'DATA',
            ellipsis: true,
            width: sizes?.[6] || defaultColumnsWidth[6],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={SYSTEM_LOG_TABLE}>
                    ERROR_STACK
                </ResizableColumn>
            ),
            dataIndex: 'ERROR_STACK',
            key: 'ERROR_STACK',
            ellipsis: true,
            width: sizes?.[7] || defaultColumnsWidth[7],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
        },
    ];
};
