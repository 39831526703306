import { RefObject } from 'react';
import { InputRef } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATE_FORMAT_WITH_TIME, INTGR_STATS_TABLE } from '../../../constants';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { IntgrStat } from '../../../models/IntgrStat';

const defaultColumnsWidth = {
    0: 50,
    1: 50,
    2: 100,
    3: 150,
    4: 150,
    5: 50,
    6: 50,
    7: 50,
    8: 100,
    9: 300,
    10: 50,
    11: 50,
} as const;

export const stringContainsColumns = ['status', 'errorMessage'];
export const stringExistColumns = ['id', 'changeNumber', 'objectName'];
export const dateColumns = ['startTime', 'stopTime'];

const objectName = [
    'FINMON_REFT',
    'ACCOUNTS',
    'FINMON_QUEUE',
    'CUSTOMERS',
    'STORNO',
    'TRANSACTIONS',
    'DECISION',
    'FINMON_REGISTER',
    'SALDOA',
    'ACCOUNTS_BLK',
    'FINMON_PUBLIC_RELS',
];

export const getColumns = (sizes: Record<string, number>, ref: RefObject<InputRef>): ColumnsType<IntgrStat> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={INTGR_STATS_TABLE}>
                    ID
                </ResizableColumn>
            ),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
            ...getColumnSearchProps<IntgrStat>(ref, 'string'),
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={INTGR_STATS_TABLE}>
                    Номер пачки
                </ResizableColumn>
            ),
            dataIndex: 'changeNumber',
            key: 'changeNumber',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
            ...getColumnSearchProps<IntgrStat>(ref, 'string'),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={INTGR_STATS_TABLE}>
                    Назва об'єкта данних
                </ResizableColumn>
            ),
            dataIndex: 'objectName',
            key: 'objectName',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
            filters: objectName.map((name) => ({ text: name, value: name })),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={INTGR_STATS_TABLE}>
                    Дата початку формування вітрини
                </ResizableColumn>
            ),
            dataIndex: 'startTime',
            key: 'startTime',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
            render: (value) => (value ? moment(value).format(DATE_FORMAT_WITH_TIME) : ''),
            ...getColumnSearchProps<IntgrStat>(ref, 'date'),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={INTGR_STATS_TABLE}>
                    Дата закінчення формування вітрини
                </ResizableColumn>
            ),
            dataIndex: 'stopTime',
            key: 'stopTime',
            ellipsis: true,
            width: sizes?.[4] || defaultColumnsWidth[4],
            render: (value) => (value ? moment(value).format(DATE_FORMAT_WITH_TIME) : ''),
            ...getColumnSearchProps<IntgrStat>(ref, 'date'),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={INTGR_STATS_TABLE}>
                    Кількість сформованих данних зовнішньою системою
                </ResizableColumn>
            ),
            dataIndex: 'rowsOk',
            key: 'rowsOk',
            ellipsis: true,
            width: sizes?.[5] || defaultColumnsWidth[5],
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={INTGR_STATS_TABLE}>
                    Кількість помилкових рядків в зовнішній системі
                </ResizableColumn>
            ),
            dataIndex: 'rowsErr',
            key: 'rowsErr',
            ellipsis: true,
            width: sizes?.[6] || defaultColumnsWidth[6],
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={INTGR_STATS_TABLE}>
                    Кількість фактично отриманих данних
                </ResizableColumn>
            ),
            dataIndex: 'rowsFetched',
            key: 'rowsFetched',
            ellipsis: true,
            width: sizes?.[7] || defaultColumnsWidth[7],
        },
        {
            title: (
                <ResizableColumn colIndex={8} storageKey={INTGR_STATS_TABLE}>
                    Статус формування вітрини зовнішньою системою
                </ResizableColumn>
            ),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            width: sizes?.[8] || defaultColumnsWidth[8],
            ...getColumnSearchProps<IntgrStat>(ref, 'string'),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={9} storageKey={INTGR_STATS_TABLE}>
                    Текст помилки при формувані вітрини у разі наявності
                </ResizableColumn>
            ),
            dataIndex: 'errorMessage',
            key: 'errorMessage',
            ellipsis: true,
            width: sizes?.[9] || defaultColumnsWidth[9],
            ...getColumnSearchProps<IntgrStat>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={10} storageKey={INTGR_STATS_TABLE}>
                    Ідентифікатор зовнішньої системи
                </ResizableColumn>
            ),
            dataIndex: 'externalId',
            key: 'externalId',
            ellipsis: true,
            width: sizes?.[10] || defaultColumnsWidth[10],
            ...getColumnSearchProps<IntgrStat>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={11} storageKey={INTGR_STATS_TABLE}>
                    Різниця відправлених та отриманих даних
                </ResizableColumn>
            ),
            key: 'diff',
            ellipsis: true,
            width: sizes?.[11] || defaultColumnsWidth[11],
            ...getColumnSearchProps<IntgrStat>(ref, 'string'),
            render: (_value, record) => {
                if (record.rowsOk === undefined || record.rowsFetched === undefined) {
                    return '';
                }
                const diff = record.rowsOk - record.rowsFetched;
                return <div style={{ color: diff !== 0 ? 'red' : 'inherit' }}>{diff}</div>;
            },
        },
    ];
};
